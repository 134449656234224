import styled, { css } from 'styled-components';

const fixedStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

const mobile = css`
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
`;

export default styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bg};
  z-index: ${({ theme }) => theme.zIndex.appBar - 1};

  ${({ position }) => position === 'fixed' && fixedStyles}

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
