import React, { useCallback } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { useBuzzwordPageEntry } from '@hooks/cms';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import VisibilitySensor from '@c/VisibilitySensor';
import Buzzword from '@c/Buzzword';
import NotFoundPage from './404';

const BuzzwordPage = () => {
  const theme = useTheme(),
    [, setBackground] = useColorMode(),
    buzzwordPageCmsEntry = useBuzzwordPageEntry(),
    buzzwordPageEntry = buzzwordPageCmsEntry.entry,
    seomatic = buzzwordPageCmsEntry.seomatic;

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        header: { mode: 'light', absolute: true },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg]
  );

  if (!buzzwordPageEntry) return <NotFoundPage />;

  return (
    <EntryContextProvider entry={buzzwordPageEntry}>
      <Layout seomatic={seomatic}>
        <VisibilitySensor id='ordliste' onVisible={handleOnVisible}>
          <Buzzword
            intro={buzzwordPageEntry.buzzwordIntro[0]}
            content={buzzwordPageEntry.buzzwordContent}
          />
        </VisibilitySensor>
      </Layout>
    </EntryContextProvider>
  );
};

export default BuzzwordPage;
