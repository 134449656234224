import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Box, Container, Grid, Button } from '@material-ui/core';
import ChevronRightIcon from '@assets/chevron-right.svg';
import Divider from '@c/Divider';
import StyledContent from './Content.styled';

const Content = ({ content, activeAnchors }) => {
  const contentByAnchor = activeAnchors.map(anchor => ({
    anchor,
    content: content.filter(c => c.anchor === anchor),
  }));

  return (
    <Container>
      <Box mt={{ xs: 12, md: 19.5 }}>
        <Grid container justify='center'>
          {contentByAnchor.map(a => (
            <Grid item xs={12} md={10} lg={8} id={a.anchor} key={a.anchor}>
              {a.content.map(c => {
                const hasLink = !!c.ctaUrl && !!c.ctaText;
                const isLinkInternal = /^\/(?!\/)/.test(c.ctaUrl);

                return (
                  <React.Fragment key={c.id}>
                    <StyledContent
                      mb={hasLink ? 4 : 0}
                      dangerouslySetInnerHTML={{ __html: c.text }}
                    />

                    {hasLink && (
                      <Button
                        component={isLinkInternal ? Link : 'a'}
                        to={isLinkInternal ? c.ctaUrl : undefined}
                        href={!isLinkInternal ? c.ctaUrl : undefined}
                        variant='outlined'
                        size='small'
                        endIcon={<ChevronRightIcon />}
                      >
                        {c.ctaText}
                      </Button>
                    )}

                    <Divider my={12} />
                  </React.Fragment>
                );
              })}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Content;

Content.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      anchor: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      ctaText: PropTypes.string,
      ctaUrl: PropTypes.string,
    })
  ).isRequired,
  activeAnchors: PropTypes.arrayOf(PropTypes.string).isRequired,
};
