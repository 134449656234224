import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { A_Z_LETTERS, IS_BROWSER } from './Breadcrumbs.constants';
import StyledBreadcrumb from './Breadcrumb';
import StyledBreadcrumbs from './Breadcrumbs.styled';

const Breadcrumbs = ({ color, activeAnchors }) => {
  const [breadcrumbsPos, setBreadcrumbsPos] = useState('static');
  const [breadcrumbsOffsetTop, setBreadcrumbsOffsetTop] = useState(0);

  const breadcrumbsRef = useRef(null);

  const handleOnScroll = useCallback(() => {
    if (window.pageYOffset >= breadcrumbsOffsetTop && breadcrumbsPos !== 'fixed') {
      setBreadcrumbsPos('fixed');
    } else if (window.pageYOffset < breadcrumbsOffsetTop && breadcrumbsPos !== 'static') {
      setBreadcrumbsPos('static');
    }
  }, [breadcrumbsOffsetTop, setBreadcrumbsPos, breadcrumbsPos]);

  const handleOnResize = useCallback(() => {
    if (
      breadcrumbsRef.current?.offsetTop &&
      breadcrumbsRef.current.offsetTop !== breadcrumbsOffsetTop
    ) {
      setBreadcrumbsOffsetTop(breadcrumbsRef.current.offsetTop);
    }
  }, [breadcrumbsRef, breadcrumbsOffsetTop, setBreadcrumbsOffsetTop]);

  useEffect(() => {
    if (IS_BROWSER) {
      window.addEventListener('scroll', handleOnScroll);
      window.addEventListener('resize', handleOnResize);
    }
    return () => {
      if (IS_BROWSER) {
        window.removeEventListener('scroll', handleOnScroll);
        window.addEventListener('resize', handleOnResize);
      }
    };
  });

  useEffect(() => {
    handleOnResize();
  }, [handleOnResize]);

  return (
    <StyledBreadcrumbs position={breadcrumbsPos} ref={breadcrumbsRef}>
      {A_Z_LETTERS.map(letter => (
        <StyledBreadcrumb
          to={letter}
          disabled={!activeAnchors.includes(letter)}
          color={color}
          key={letter}
        >
          {letter.toUpperCase()}
        </StyledBreadcrumb>
      ))}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;

Breadcrumbs.propTypes = {
  color: PropTypes.string.isRequired,
  activeAnchors: PropTypes.arrayOf(PropTypes.string).isRequired,
};
