import styled, { css } from 'styled-components';

const mobile = css`
  padding: ${({ theme }) => theme.spacing(19.5)}px 0;
`;

export default styled.div`
  padding: ${({ theme }) => theme.spacing(32)}px 0;
  background-color: ${({ background }) => background};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
