import React from 'react';
import { Box } from '@material-ui/core';
import { PageTypeContextProvider, PAGE_TYPE_ARTICLE } from '@context/PageTypeContext';
import { use404PageEntry } from '@hooks/cms';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import ArticleContent from '@c/ArticleContent';

const NotFoundPage = () => {
  const notFoundPageCmsEntry = use404PageEntry(),
    notFoundPageEntry = notFoundPageCmsEntry.entry,
    seomatic = notFoundPageCmsEntry.seomatic,
    articleContent = notFoundPageEntry.articleContent;

  return (
    <PageTypeContextProvider value={PAGE_TYPE_ARTICLE}>
      <EntryContextProvider entry={notFoundPageEntry}>
        <Layout seomatic={seomatic}>
          <Box mb={{ xs: 12.5, md: 20 }}>
            <ArticleContent content={articleContent} textAlign='center' useOriginalImage />
          </Box>
        </Layout>
      </EntryContextProvider>
    </PageTypeContextProvider>
  );
};

export default NotFoundPage;
