import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Breadcrumbs from './Breadcrumbs';
import Intro from './Intro';
import Content from './Content';

const Buzzword = ({ intro, content }) => {
  const activeAnchors = useMemo(
    () => content.map(c => c.anchor).filter((a, i, s) => s.indexOf(a) === i),
    [content]
  ).sort();

  return (
    <Box mb={19.5}>
      <Intro background={intro.backgroundColor} text={intro.text} />
      <Breadcrumbs color={intro.backgroundColor} activeAnchors={activeAnchors} />
      <Content content={content} activeAnchors={activeAnchors} />
    </Box>
  );
};

export default Buzzword;

Buzzword.propTypes = {
  intro: PropTypes.shape({
    id: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      anchor: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      ctaText: PropTypes.string,
      ctaUrl: PropTypes.string,
    })
  ).isRequired,
};
