import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from '@material-ui/core';
import StyledIntro from './Intro.styled';

const Intro = ({ background, text }) => (
  <StyledIntro background={background}>
    <Container>
      <Grid container>
        <Grid item xs={12} md={10} lg={6} dangerouslySetInnerHTML={{ __html: text }} />
      </Grid>
    </Container>
  </StyledIntro>
);

export default Intro;

Intro.propTypes = {
  background: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
