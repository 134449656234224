import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { Link } from 'react-scroll';

const mobile = css`
  flex: 0 0 auto;
  width: ${({ theme }) => theme.spacing(5)}px;
  height: ${({ theme }) => theme.spacing(5)}px;
`;

export default styled(Link).attrs(() => ({
  activeClass: 'active',
  spy: true,
  smooth: true,
  duration: 500,
  offset: -156,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing(10)}px;
  height: ${({ theme }) => theme.spacing(10)}px;
  color: ${({ theme }) => theme.colors.dark};
  text-decoration: none;

  &:hover:not([disabled]),
  &:active:not([disabled]),
  &:focus:not([disabled]),
  &.active {
    background-color: ${({ color }) => color};
    cursor: pointer;
  }

  &[disabled] {
    color: ${({ theme }) => transparentize(0.76, theme.colors.dark)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
