import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export default styled(Box)`
  p {
    font-family: schoolbook, serif;
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
    line-height: ${({ theme }) => theme.typography.body2.lineHeight};

    ${({ theme }) => theme.breakpoints.down('sm')} {
      font-size: ${({ theme }) => theme.typography.body2[theme.breakpoints.down('sm')].fontSize};
      line-height: ${({ theme }) =>
        theme.typography.body2[theme.breakpoints.down('sm')].lineHeight};
    }
  }
`;
